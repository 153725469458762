import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"

const LinksContainer = styled.section`
  ${tw`relative z-10 flex flex-col w-full`}
`
const LinkTab = styled.div`
  ${tw`relative flex justify-center text-center items-center w-full px-20 py-5 h-auto lg:h-20 2xl:h-[90px] text-white text-16 lg:text-18 2xl:text-[25px] cursor-pointer font-ubuntu font-bold`}
`
const LinkTabExternal = styled.a`
  ${tw`relative flex justify-center text-center items-center w-full px-20 py-5 h-auto lg:h-20 2xl:h-[90px] text-white text-16 lg:text-18 2xl:text-[25px] cursor-pointer font-ubuntu font-bold`}
`

const WpAcfFullWidthLinksModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfFullWidthLinksBlock
  const sectionAttributes = moduleData.attributes

  const handleClick = anchor => {
    const targetElement = document.getElementById(anchor)
    if (targetElement) {
      const yOffset = -100
      const yCoordinate =
        targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: yCoordinate, behavior: "smooth" })
    } else {
      console.error(`Element with ID ${anchor} not found.`)
    }
  }

  return (
    <LinksContainer className="links-section">
      {blockData.links.map((link, i) => {
        if (link === null) return null
        const isExternalLink = /^https?:\/\//.test(link.linkAddress.url)
        const anchorId = link.linkAddress.url.replace(/^.*#/, "")

        if (isExternalLink) {
          return (
            <LinkTabExternal
              href={link.linkAddress.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`link-tab link-tab--external tab-color-${link.tabColor}`}
              key={`${link}-${i}`}
            >
              {link.linkAddress.title}
            </LinkTabExternal>
          )
        } else {
          return (
            <LinkTab
              className={`link-tab tab-color-${link.tabColor}`}
              key={`${link}-${i}`}
              onClick={() => handleClick(anchorId)}
            >
              {link.linkAddress.title}
            </LinkTab>
          )
        }
      })}
    </LinksContainer>
  )
}

WpAcfFullWidthLinksModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfFullWidthLinksModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfFullWidthLinksModuleBlock
